<template>
  <div>
        <vue-header title="问诊" isReturn="true" />
        <face active="3"/>
        <div class="face-device flex flex-x">
              <img src="../../../images/ask-device.png"  />
          </div>
        <div class="step-mint">
            <div class="mint-color">您即将进入<span>[问诊]</span></div>
            <div class="mint-color trans-size">请如实填写问诊信息，避免检测结果出现误差</div>
        </div>
        <button class="click-photo" @click="questionFun">开始问诊</button>
  </div>
</template>
<script>
import vueHeader from '@/components/header' 
import face from '@/components/face'
export default {
  name: 'confirmAsk',
  components:{
    vueHeader,
    face
  },
  methods:{
    //进入问诊
    questionFun(){
        let flow = sessionStorage.getItem('flow');
        let device = sessionStorage.getItem('device');
        let flow_step = "";
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }
        if((flow_step==5||flow_step==6)&&!(device=='android' || device=='windows')){
            this.$router.push({
                name:'prescribeQuestion'
            });
        }else{
            this.$router.push({
                name:'question'
            });
        }
    }
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'confirmAsk';
</style>